import PropTypes from 'prop-types'
import React from 'react'

import './list.scss'

class List extends React.Component {
  render () {
    const listItems = this.props.items.map((item) =>
      <this.props.type item={item} enlarge={this.props.enlarge} key={`key-list-item-${item.id}`}/>
    )

    return (
      <div className={`list ${this.props.enlarge ? 'list--enlarge' : ''}`}>
        <h1 className="list__title">{this.props.title}</h1>
        <div className="list__list">
          {listItems}
        </div>
      </div>
    )
  }
}

List.defaultProps = {
  items: [],
  title: 'Title'
}

List.propTypes = {
  items: PropTypes.array
}

export default List
