import React from 'react'
import { graphql } from 'gatsby'

import { TagData, TagDataBoosts } from '../data/projects'
import Layout from '../components/Layout/layout-side'
import ProcessBlogs from '../components/datacontent'
import SEO from '../components/SEO/seo'
import Museum from '../components/Museum/list'
import Portfolio from '../components/Museum/Item/portfolio'
import TagList from '../components/Taglist/taglist'

class SecondPage extends React.Component {
  constructor (props) {
    super(props)
    const { data } = this.props
    const allItems = ProcessBlogs(data.allMdx.edges, data.allProjectsJson.edges)

    this.state = {
      selectedTags: [],
      bestTags: [],
      filteredItems: allItems,
      allItems: allItems,
      firstRound: true
    }
    this.selectTag = this.selectTag.bind(this)
    this.removeTag = this.removeTag.bind(this)
  }

  componentDidMount () {
    this.updateTopTags()
  }

  updateTopTags (
    filteredItems = [...this.state.filteredItems],
    selectedTags = this.state.selectedTags
  ) {
    const tags = filteredItems.map((item) => {
      return item.tags
    })
    const distribution = tags.flat().reduce((acum, cur) => Object.assign(acum, { [cur]: (acum[cur] | 0) + 1 }), {})

    let bestTags = []
    for (const [key, value] of Object.entries(distribution)) {
      if (selectedTags.length > 0 && selectedTags.map(st => st.value).includes(`${key}`)) {
        // Remove selected Tags
      } else if (value < 2) {
        // Remove empty Tags or non contributing Tags (less than 2 items)
      } else if (key[0] === '5' || key[0] === '4') {
        // Remove time related tags (start with 5)
      } else if (key[0] === '9') {
        // Remove tags for homepage (start with 9)
      } else if (key === '1' || key === '2' || key === '3') {
        // Boost tags for top level on first go
        const boost = TagDataBoosts[key] || 2

        if (this.state.firstRound) {
          const boost = TagDataBoosts[key] || 2
          bestTags.push({ i: key, v: (value * boost * 4), vOrg: value })
          this.setState({ firstRound: false })
        } else {
          bestTags.push({ i: key, v: (value * boost), vOrg: value })
        }
      } else if (key === '100') {
        // Remove tags "blog"
      } else {
        const boost = TagDataBoosts[key] || 2
        bestTags.push({ i: key, v: (value * boost), vOrg: value })
      }
    }

    bestTags = bestTags.sort((a, b) => {
      if (a.v < b.v) return 1
      if (b.v < a.v) return -1

      return 0
    }).slice(0, 5)

    bestTags = bestTags.map(({ i, v }) => {
      return { name: TagData[i], value: i }
    })

    this.setState({ bestTags: bestTags })
  }

  updateFilteredItems (selectedTags = this.state.selectedTags) {
    let filteredItems = this.state.allItems

    selectedTags.forEach(tag => {
      filteredItems = filteredItems.filter(item => {
        return item.tags.includes(Math.trunc(tag.value))
      })
    })

    this.setState({ filteredItems: filteredItems })
    this.updateTopTags(filteredItems, selectedTags)
  }

  selectTag (tag) {
    const selectedTags = this.state.selectedTags
    selectedTags.push(tag)
    this.setState((tag) => ({ selectedTags: selectedTags }))

    this.updateFilteredItems(selectedTags)
  }

  removeTag (tag) {
    let selectedTags = this.state.selectedTags
    selectedTags = selectedTags.filter(item => item.value !== tag.value)
    this.setState((tag) => ({ selectedTags: selectedTags }))

    this.updateFilteredItems(selectedTags)
  }

  render () {
    return (
      <Layout location={this.props.location}>
        <>
          <SEO title="Museum with art and cases" imagefile="all" location={this.props.location} />
          <h2>Filter the collection</h2>
          <p>To filter, select the tag that best fit with what you are looking for. New options will appear.</p>
          <TagList tagType="best" items={this.state.bestTags} handleClick={this.selectTag}/>
          <TagList tagType="selected" header="Selected Tags" items={this.state.selectedTags} handleClick={this.removeTag}/>
        </>
        <>
          <Museum type={Portfolio} title="Museum" items={this.state.filteredItems} enlarge={true}/>
        </>
      </Layout>
    )
  }
}

export default SecondPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allProjectsJson {
      edges {
        node {
          ...ProjectNodes
        }
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          ...BlogNodes
        }
      }
    }
  }
`
