import React from 'react'
import PropTypes from 'prop-types'
import { StickyContainer } from 'react-sticky'

import Footer from '../Footer/footer'
import Header from '../Header/header'
import './layout-side.scss'

class Layout extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      show: true
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (e) {
    e.preventDefault()
    this.setState({
      show: !this.state.show
    })
  }

  render () {
    return (
      <StickyContainer>
        <div className="layout-side__header">
          <Header
            location={this.props.location}
            alt="Museum"
            title="Museum"
          />
        </div>
        <div className={this.state.show ? 'layout-side layout-side--show' : 'layout-side'}>
          <div className="layout-side__side-wrapper">
            <div className="layout-side__side">
              <a className="layout-side__toggle" onClick={this.handleClick} href="#toggle">
                <span>Filters are {this.state.show ? 'show' : 'hidden'}</span>
              </a>
              {this.props.children[0]}
            </div>
          </div>
          <main className="layout-side__main-wrapper">
            <div className="layout-side__main">
              <div>
                {this.props.children[1]}
              </div>
              <Footer />
            </div>
          </main>
        </div>
      </StickyContainer>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
