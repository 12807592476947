import PropTypes from 'prop-types'
import React from 'react'

import './taglist.scss'

class Tag extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      show: true
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (e) {
    e.preventDefault()
    this.props.handleClick({ name: this.props.name, value: this.props.value })
  }

  render () {
    return (
      <a href="#tagclick" onClick={this.handleClick}>{this.props.name}</a>
    )
  }
}

class TagList extends React.Component {
  render () {
    const listItems = this.props.items.map((tag) =>
      <li key={`Taglist-${tag.value}`}><Tag value={tag.value} name={tag.name} handleClick={this.props.handleClick}/></li>
    )
    let header = ''
    if (this.props.header && this.props.items.length > 0) {
      header = <h3>{this.props.header}</h3>
    }
    return (<div className="tags">{header} <ul className={'tags--' + this.props.tagType}>{listItems}</ul></div>)
  }
}

TagList.defaultProps = {
  items: []
}

TagList.propTypes = {
  items: PropTypes.array
}

export default TagList
